import styled from "styled-components"

const PressClippingWrapper = styled.div`
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  padding-top: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  min-height: calc(
    100vh -
      ${({
        theme: {
          footer: { mobile },
        },
      }) => mobile}
  );
  color: white;

  @media only screen and (min-width: 576px) {
    padding-top: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
    min-height: calc(
      100vh -
        ${({
          theme: {
            footer: { desktop },
          },
        }) => desktop}
    );
  }
`

const PressClippingContent = styled.div`
  padding: ${({
    theme: {
      content: {
        padding: { mobile },
      },
    },
  }) => mobile};
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};
  padding-top: 6rem;

  /* @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: 15rem;
  } */

  /* @media only screen and (min-width: 768px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-top: 10rem;
    padding-bottom: 15rem;
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-top: 10rem;
    padding-bottom: 15rem;
  } */
`
const MagazinContainer = styled.div`
  /* background-color: ${({
    theme: {
      palette: { white },
    },
  }) => white}; */
  max-width: 1000px;
  margin: 0 auto;
`

const Title = styled.h1`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
`

const Subtitle = styled.h3`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s36};
  color: ${({
    theme: {
      palette: { black },
    },
  }) => black};
`

const Paragraph1 = styled.p`
  margin: ${({ theme: { spacing } }) => spacing.s40} 0;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s21};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w700};
  color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
`

const Paragraph2 = styled.p`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s21};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w400};
  color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  line-height: 20px;
`

const GetInTouchContainer = styled.div`
  margin-top: 50px;
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
`

const GetInTouchTitle = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s31};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w400};
  color: ${({
    theme: {
      palette: { white },
    },
  }) => white};
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
`
const Email = styled.span`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s31};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w700};
  color: ${({
    theme: {
      palette: { white },
    },
  }) => white};
  text-align: center;
`

export {
  PressClippingWrapper,
  PressClippingContent,
  MagazinContainer,
  Title,
  Subtitle,
  Paragraph1,
  Paragraph2,
  GetInTouchContainer,
  GetInTouchTitle,
  Email,
}
